import 'assets/css/mapViewer.css'

import React from 'react';
import { Panel } from 'rsuite';
import { t } from 'i18next';

import PanelHeader from 'components/panels/PanelHeader';
import { OnboardCountSidebar, OnboardCountContext } from 'features/onboardCount';
import { CrossSectionRoadCountContext, CrossSectionRoadCountSidebar } from 'features/crossSectionRoadCount';
import WebmapContext from '../context/WebmapContext';
import MapCanvas from './MapCanvas';
import MapElement from './MapElement';
import MapSidebar from './MapSidebar';
import MapToolbar from './MapToolbar';
import { PageSection } from 'components/pages';
import MapProgressBar from './MapProgressBar';
import { logEvent } from 'features/logging';


const MapViewer = ({
    id,
}) => {

    const {
        webmap,
        joins,
        arcGISMap,
    } = React.useContext(WebmapContext);

    const onboardCount = React.useContext(OnboardCountContext);
    const crossSectionRoadCount = React.useContext(CrossSectionRoadCountContext);
    const toolbarHeight = onboardCount?.toolbarRef?.current?.offsetHeight ||
        crossSectionRoadCount?.toolbarRef?.current?.offsetHeight ||
        0;

    const containerId = id ? `${id}` : `${webmap?.id}`;

    const [rendering, setRendering] = React.useState(false);
    const [initialized, setInitialized] = React.useState(false);
    const renderingLayerIdsRef = React.useRef([]);
    const renderRef = React.useRef({});

    function onRenderStarted(layerId) {
        const renderingLayerIds = renderingLayerIdsRef.current;
        if (!renderingLayerIds.length) {
            console.log('Started rendering layers')
            setRendering(true);
            logEvent('mapLayersRendering');
        }

        if (!renderingLayerIds.includes(layerId)) {
            renderingLayerIds.push(layerId);
        }

    };

    function onRendered(layerId) {
        const renderingLayerIds = renderingLayerIdsRef.current;

        if (renderingLayerIds.includes(layerId)) {
            renderingLayerIds.splice(renderingLayerIds.indexOf(layerId), 1);
        }

        if (!renderingLayerIds.length) {
            console.log('Stopped rendering layers');

            Object.values(arcGISMap.joinLayers || {}).map(layer => {
                arcGISMap.map.reorder(layer, layer.layerIndex || 0)
            })
            logEvent('mapLayersRendered');
            setRendering(false);
        }

    };

    React.useEffect(() => {
        setInitialized(arcGISMap?.initialized);
    }, [arcGISMap?.initialized]);
    

    React.useEffect(() => {
        console.log('Running layer renderers changed...')
        const runningRenders = renderingLayerIdsRef.current;

        Object.entries(renderRef.current).forEach(([name, value]) => {
            if (value && !runningRenders.includes(name)) {
                renderRef.current[name] = false;
            }
        })

        runningRenders.forEach(name => {
            renderRef.current[name] = true;
        })

        const running = runningRenders.length;
        const total = Object.keys(renderRef.current).length;
        arcGISMap?.setTaskProgress('render', total-running, total);

        if (!running) {
            renderRef.current = {}
        } 

    }, [`${renderingLayerIdsRef.current}`])

    return (
            
        <PageSection id='mapPanel' margin={toolbarHeight + 10 } offset={toolbarHeight + 10} >
            <Panel
                className='mapViewerPanel'
                bordered
                shaded
                header={<PanelHeader
                    title={t('Map')}
                    toolbar={<MapToolbar />}
                />}
            >
                <div className="mapLayout" >
                    <MapCanvas
                        containerId={containerId}
                        rendering={false}
                    >
                        {initialized && (webmap?.elements || []).reverse().map(element => {
                            return (
                                <MapElement
                                    key={element?.id}
                                    element={element}
                                    joins={joins?.[element?.id]}
                                    onRenderStarted={onRenderStarted}
                                    onRendered={onRendered}
                                />
                            )
                        })}
                    </MapCanvas>

                    <MapSidebar>
                        { onboardCount && <OnboardCountSidebar /> }
                        { crossSectionRoadCount && <CrossSectionRoadCountSidebar /> }
                    </MapSidebar>

                    <MapProgressBar />

                </div>

            </Panel>
        </PageSection>

    )
};


export default MapViewer;
