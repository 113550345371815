import { t } from 'i18next'
import { Join } from 'features/webmap'

import { BORDER_CROSSING_LAYER, LAYER_ORDER } from '../constants'
import borderCrossingRenderer from 'features/crossSectionRoadCount/lib/arcGISRenderers/borderCrossingRenderer'
import borderCrossingLabeling from 'features/crossSectionRoadCount/lib/arcGISLabels/borderCrossingLabeling'


export const getBorderCrossingJoins = () => {
    return [Join({
        index: LAYER_ORDER.indexOf(BORDER_CROSSING_LAYER),
        name: BORDER_CROSSING_LAYER,
        legendEnabled: false,
        renderer: borderCrossingRenderer(),
        label: t('OKKF/BorderCrossings'),
        labelingInfo: borderCrossingLabeling(),
        objectIdField: 'OBJECTID',
        baseField: 'Name',
        targetField: 'Name'
    })]
}
